import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const contactsApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        userContacts: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/contacts',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        deleteContact: builder.mutation({
            query: (contactmap_id) => ({
                url: `/v0/user/contacts/delete/${contactmap_id}`,
                method: RequestTypes.DELETE,
            })
        })
    })
});

export const { useUserContactsMutation, useDeleteContactMutation} = contactsApis;
