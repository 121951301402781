import Sidebar from '@Components/Sidebar'
import Navbar from '@Components/navbar'
import React from 'react'
import UserDetailsComponent from './components/UserDetailsComponent'

const UserDetails = () => {
  return (
    <div>
        <Navbar/>
        <div className='flex'>
            <Sidebar/>
            <UserDetailsComponent/>
        </div>
    </div>
  )
}

export default UserDetails