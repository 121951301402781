const styles = {
    FORM: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #B8B8B8',
      borderRadius: '4rem',
      padding: '3rem',
    },
    LOGO_WRAPPER: {
      display: 'flex',
      justifyContent: 'center',
    },
    TEXT_P: {
      fontSize: '18px',
      color: '#4B4B4B',
      marginTop: '1rem', 
    },
    CARD_WRAPPER: {
      // display: 'flex',
      // flexDirection: 'column',
      // gap: '0.75rem', 
      // marginTop: '2rem', 
    },
    ACTIONS_CONTAINER: {
      width: '100%',
      cursor: 'pointer',
      fontSize: '12px', 
    },
    ACTIONS_WRAPPER: {
      display: 'flex',
      justifyContent: 'center',
      gap: '0.75rem', 
      width: '100%',
      marginTop: '1rem', 
    },
    ICON_STYLE: {
      height: '1.25rem', 
      width: '1.25rem', 
    },
    SIGNUP_CONTAINER: {
      border: '1px solid #a2a2a2',
      borderRadius: '16px',
      height: 'fit-content',
      padding: '1.5rem'
    }
  };
  
  export default styles;
  