const styles = {
    ADD_BUTTON: {
        color: 'white',
        backgroundColor: '#208A4A',
        padding: "0.85rem",
        borderRadius: "10px",
        fontSize: "14px"
    },
    AUTOMATION_EDIT_CONTAINER: {
        // height: 'fit-content'
    },
    deleteContactModalContent: {
        height: '70%',
        width: '500px',
        margin: 'auto',
        fontFamily: 'DM Sans',
        borderRadius: '20px'
    },
    addLinkModalContent: {
        height: '350px',
        width: '500px',
        margin: 'auto',
        fontFamily: 'DM Sans',
        borderRadius: '20px'
    },
    modalHeader: {
        fontSize: '1.3rem',
        color: "#000000d9",
        borderBottom: '2px solid #22242626'
    },
    modalContent: {
        fontSize: '1rem'
    },
    selectedPostCaption: {
        whiteSpace: 'pre-line'
    },
    enterKeywordInput: {
        outline: 0,
        // '&:focus-visible': {
        //     outline: '0',
        // }
    }
}

export default styles