// export const RESEND_OTP_TIME = 15;
// export const OTP_LENGTH = 6;
// export const EMPTY_STRING = '';

export enum Tokens {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token'
}

export enum Confetti {
  DELAY = 0,
  DURATION = 4000,
  FORCE = 0.8,
  PARTICLE_COUNT = 150,
  WIDTH = 1600
}


export type Map = Record<string, any>;
export type StringMap = Record<string, string>;