import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const signupApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        userSignup: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/signup',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        verifyOtp: builder.mutation({
            query: (payload) => ({
                url: '/v0/verify',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useUserSignupMutation, useVerifyOtpMutation } = signupApis;
