import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const loginApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (payload) => ({
        url: '/v0/user/login',
        method: RequestTypes.POST,
        body: payload
      })
    })
  })
});

export const { useUserLoginMutation } = loginApis;
