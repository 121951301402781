import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const loginApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        connectFacebook: builder.mutation({
            query: (payload) => ({
                url: '/v0/connect/facebook',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useConnectFacebookMutation } = loginApis;
