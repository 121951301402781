import React, { useState } from 'react';
import {TrashPNG} from '@assets/index'
import {Cross1Icon} from '@radix-ui/react-icons'
import styles from './styles';


interface EditModalInterface{
  data:any,
  onCloseModal: ()=>void,
}


interface CustomAttributesProps {
  data: any
}

const ContactEditModal = (props: EditModalInterface) => {

  const [customParams, setCustomParams] = React.useState([
    {
      "key": "att1",
      "value": "val1",
      "m_id": 1223121
    },
    {
      "key": "att1",
      "value": "val1",
      "m_id": 122312
    }
  ])
  const [details, setDetails] = React.useState({
    allowBroadcast: props.data?.allowBroadcast,
    customParams: [props.data?.custom_attributes],
  })
  
  
  const handleSave = () => {
    // Handle save logic
    console.log('Save clicked');
    console.log("details:",details);
    
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target

    setDetails(prev => ({
      ...prev,
      [name] : value
    }))
  }

  const handleChecked = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = e.target
    setDetails(prev => ({
      ...prev,
      [name]:checked
    }))
    
  }


  const handleCustomAttributionChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr_idx: number) => {
    const temp_attr_arr = [...customParams]
    if (e?.target?.name == 'custom_att_key') {
      temp_attr_arr[attr_idx].key = e.target.value
    };
    if (e?.target?.name == 'custom_att_value') {
      temp_attr_arr[attr_idx]['value'] = e.target.value
    };

    setCustomParams(temp_attr_arr);

  }


  




  const CustomAttributes = (props: CustomAttributesProps) => {

    return (
      <div>
        {props.data.map((attr: any, idx:number) => {
          return(
          <div key={attr.m_id} className='mt-3 flex items-center gap-4'>
              <input type='text' name='custom_att_key' onChange={(e) => handleCustomAttributionChange(e, idx)}
              value={attr?.key}
              className='px-2 py-1 rounded-[0.25rem] bg-[#f3f4f6]' />
              <input type='text' name='custom_att_value' onChange={(e) => handleCustomAttributionChange(e, idx)} 
              value={attr?.value}
              className='px-2 py-1 rounded-[0.25rem] bg-[#f3f4f6]' />
            <div className="items-center flex hover:cursor-pointer">
              <img src={TrashPNG} />
            </div>
          </div>
        )})}
      </div>
    )
  }


  

  return (
    <div style={styles.modal} className='w-full'>
      <div className='flex justify-between items-center text-xl font-bold'>
        <h1>Edit Contact</h1>
        <div className='font-bold' onClick={(e)=>props.onCloseModal()}><Cross1Icon/></div>
      </div>
      <div className='border-b-[1px] mt-4 border-[#d1d5db]'></div>
      <div className='flex flex-col justify-around items-center mt-[2rem] gap-6'>
        {props.data?.profile_pic ? (<div className='' hidden={isNaN(props.data?.profile_pic) ? true : false}>
            <img src={props.data?.profile_pic} className='w-[6vw] h-[8vh] border-2' />
          </div>):(<></>)}
          {/* <div className='' hidden={isNaN(props.data?.profile_pic) ? true : false}>
            <img src={props.data?.profile_pic} className='w-[6vw] h-[8vh] border-2' />
          </div> */}
        <div className='flex items-center gap-[4rem]'>
          <div className='flex flex-col gap-1'>
            <label htmlFor='name'>Name</label>
            <input id="name" type="text" className='border-[1px] border-[#9ca3af] rounded-[5px] px-2 py-1 disabled:bg-[#ffffff]' value={props.data?.name} disabled name='name' />
          </div>
          <div className='flex flex-col gap-1'>
            <label htmlFor='phone'>Username</label>
            <input id="phone" type="text" className='border-[1px] border-[#9ca3af] rounded-[5px] px-2 py-1 disabled:bg-[#ffffff]' value={props.data?.username} disabled name='username' />
          </div>
        </div>
        <div className='flex items-center gap-4 relative right-[5rem]'>
          <div className='flex items-center gap-2'>
            <input type='checkbox' id='checkbox' className='appearance-none w-4 h-4 border-[#fca5a5] border-2 bg-[#fee2e2] checked:bg-[#ef4444]' value={details.allowBroadcast} onChange={(e) => handleChecked(e)} name='allowBroadcast' />
            <label htmlFor='checkbox' className='font-bold'>Allow Broadcast</label>
          </div>
          <div className='flex items-center gap-2'>
            <input type='checkbox' id='checkbox' className='appearance-none w-4 h-4 border-[#fca5a5] border-2 bg-[#fee2e2] checked:bg-[#ef4444]' />
            <label htmlFor='checkbox' className='font-bold'>Allow SMS</label>
          </div>
        </div>
        <div>
          <p>Custom Attribute<span className='text-[#6b7280]'>(Optional)</span></p>
          <div>
            <div className='flex items-center gap-[8rem] mt-4'>
              <p>Attribute</p>
              <p>Value</p>
            </div>
            <CustomAttributes data={customParams} />
          </div>
        </div>
        <div className='relative right-[9rem]'>
          <button className='px-2 py-1 border-[1px] rounded-[6px] bg-[#dcfce7] text-[#16a34a] border-[#16a34a]' onClick={() => console.log()}>+ Add Attribute</button>
        </div>
        <div className='relative right-[1rem]'>
          <div className='flex items-center gap-[3rem]'>
            <p>Business follows user</p>
            <p>User follows business</p>
          </div>
          <div className='flex gap-4 mt-4'>
            <input type='text' disabled value={props.data?.is_business_follow_user == 0 ? "false" : "true"} className='px-2 py-1 bg-[#d1fae5] rounded-[5px]' />
            <input type='text' disabled value={props.data?.is_user_follow_business == 0 ? "false" : "true"} className='px-2 py-1 bg-[#d1fae5] rounded-[5px]' />
          </div>
        </div>
        <div className='relative left-[8rem] mt-3'>
          <div className='flex gap-4'>
            <button className='border-[1px] px-4 py-2 rounded-[6px]' onClick={(e) => props.onCloseModal()}>Cancel</button>
            <button className='px-6 py-2 rounded-[6px] bg-[#16a34a] text-[#ffffff]' onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactEditModal;