import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";

import { Logo1024PNG } from "../../assets/index";
import { Confetti } from "../../constants/common";

import styles from "./styles";
import LocalStorage from "@Utils/storage";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Constants/routes";


const IgConnected = () => {
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsExploding(true);
    }, Confetti.DELAY);
    setTimeout(() => {
      setIsExploding(false);
    }, Confetti.DURATION);
  }, []);


  const navigate = useNavigate();

  const takeToDashboard = () => {
    LocalStorage.removeItem('ppu')
    LocalStorage.removeItem('igu')
    navigate(ROUTES.AUTOMATIONS)
  }
  

  return (
    <div className="container h-full" style={styles.FLEX_CENTER}>
      <div className="smMobile:w-[470px]">
        <div style={styles.FORM} className="shadow-xl">
          <div className="container-head">
            <div style={styles.LOGO_WRAPPER}>
              <img src={Logo1024PNG} alt="" />
            </div>
          </div>
          <div className="flex-col" style={styles.CONTENT}>
            {isExploding && (
              <ConfettiExplosion
                force={Confetti.FORCE}
                duration={Confetti.DURATION}
                particleCount={Confetti.PARTICLE_COUNT}
                width={Confetti.WIDTH}
              />
            )}
            <div className="container-msg flex flex-col justify-center items-center my-[2rem]">
              <img style={styles.PROFILE_IMAGE} src={LocalStorage.getItem('ppu') || ''} alt="" />
              <p className="text-center" style={styles.TEXT_BOLD}>
                Congratulations, <br /> {LocalStorage.getItem('igu') || ''} is connected!
              </p>
            </div>
            <button className="btn-blue" onClick={() => takeToDashboard()}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IgConnected;
