import { toast } from 'react-toastify'

export const displayToast = (type: string, message:string) => {
    switch(type){
        case 'success':
            return toast.success(message, {theme: 'colored'})
        case 'error':
            return toast.error(message, {theme: 'colored'})
        case 'warning':
            return toast.warn(message, {theme: 'colored'})
        case 'default':
            return toast(message, {theme: 'colored'})
    }
}