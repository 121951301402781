import React, { useEffect } from 'react'
import styles from "./styles";
import { CRYPTOJS_IV, CRYPTOJS_KEY } from '@Constants/config';
import { useConnectFacebookMutation } from './apiSlice';
import { FBWhite, Selfie2 } from '@assets/index';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@Constants/routes';
var CryptoJS = require("crypto-js");



declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

function ConnectFacebook() {


  const navigate = useNavigate();

  var key = CryptoJS.enc.Utf8.parse(CRYPTOJS_KEY);
  var iv = CryptoJS.enc.Utf8.parse(CRYPTOJS_IV)
  

  const [connFb, { data: connFbData, error: connFbErr, isLoading: connFbLoading }] = useConnectFacebookMutation()

  
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1054441078939265',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v18.0'
      });
    };

    (function (d, s, id) {
      let js:any, fjs:any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  useEffect(() => {
    if (connFbData) {
      console.log(connFbData)
      if (connFbData.status) navigate(ROUTES.SIGNUP_CONNECT_PAGE)
    } else if (connFbErr) {
      // setShowError(parseErrorMessage(verifyErr));
      console.error(connFbErr)
    }
  }, [connFbData, connFbErr]);

  const handleClickLoginButton = () => {
    window.FB.login((response:any) => {
      if (response.authResponse) {
        console.log(response.authResponse.accessToken);
        var encrypted = CryptoJS.AES.encrypt(response.authResponse.accessToken, key, { iv: iv, mode: CryptoJS.mode.CBC });
        encrypted = encrypted.toString();
        console.log('encrypted', encrypted);
        connFb({ enc_at: encrypted })
        
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope:'email,read_insights,pages_show_list,business_management,pages_messaging,pages_messaging_subscriptions,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,instagram_manage_messages,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,instagram_manage_events,public_profile'});
  }

  return (
    <div className="grid grid-cols-2">
      <div className="h-[100vh] bg-[#EAE8FD] px-[1rem] py-[1rem]">
        <div>
          <p className="font-bold text-2xl">AutoChat</p>
        </div>
        <div className="mt-[1rem]">
          <img src={Selfie2} className="w-[70%] item-center justify-center" />
          <div className="mt-[4rem]">
            <p className="font-bold text-[48px]">Connect Instagram</p>
            <p className="text-[#787781]">Follow the instruction to connect to instagram, account</p>
          </div>
        </div>
      </div>
      <div className="flex item-center justify-center  align-middle my-auto">
        {/* <div className="absolute right-10 top-10">
            <button className="border-2 border-[#7772FF] font-bold rounded-3xl p-3 text-[14px]">Get Started Free</button>
        </div> */}
        <div className="w-[80%] flex item-center justify-center" style={styles.SIGNUP_CONTAINER}>
          <div>
            <p className="font-bold text-2xl">
              a few steps away to connect<br />your instagram
            </p>
            <p className="w-[80%] text-[12px] mt-4 text-[#9F9F9F]">To connect your instagram, you'll have to login with facebook</p>
            <div className="bg-[#0966FF] flex items-center justify-center gap-4 px-4 py-2 rounded-[0.5rem] w-[70%] mt-12 relative left-[2.5rem]">
              <img src={FBWhite} />
              <span className="font-bold text-white text-[14px]" onClick={handleClickLoginButton}>Signup with facebook</span>
            </div>
            <div className="mt-3 flex justify-center flex-col items-center text-[14px]">
              <p className="text-[#9F9F9F]">By signing up, you agree to AutoChat's</p>
              <p className="text-[#0966FF]">Terms of Service <span className="text-[#9F9F9F]">and</span> Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectFacebook