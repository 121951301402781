const styles = {
  FLEX_CENTER: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  FORM: {
    border: "1px solid #B8B8B8",
    borderRadius: "4rem",
    padding: "3rem",
    backgroundColor: "#fcfcfc",
  },
  LOGO_WRAPPER: {
    display: "flex",
    justifyContent: "center",
  },
  CONTENT: {
    marginBottom: "4rem",
    display: "flex",
    alignItems: "center",
  },
  PROFILE_IMAGE: {
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    marginRight: "0.75rem", // Assuming 'mr-3' is equivalent to 'margin-right: 0.75rem'
  },
  TEXT_BOLD: {
    fontWeight: "800", // Assuming 'font-extrabold' is equivalent to 'font-weight: 800'
    marginTop: "0.75rem", // Assuming 'my-3' is equivalent to 'margin-top: 0.75rem; margin-bottom: 0.75rem;'
    marginBottom: "0.75rem",
  },
};

export default styles;
