import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const automationApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAutomations: builder.query({
            query: (payload) => ({
                url: '/v0/user/automations',
                method: RequestTypes.GET,
                params: payload
            })
        }),
        createAutomation: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/automations/create',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        updateAutomationStatus: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/automations/update/status',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        deleteAutomation: builder.mutation({
            query: (automation_id) => ({
                url: `/v0/user/automation/delete/${automation_id}`,
                method: RequestTypes.DELETE,
            })
        })
    })
});

export const { useLazyGetAutomationsQuery, useCreateAutomationMutation, useUpdateAutomationStatusMutation, useDeleteAutomationMutation } = automationApis;
