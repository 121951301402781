const styles = {
  LOGIN_CONTAINER: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: 'linear-gradient(294.02deg, rgba(255, 255, 255, 0.09) 17.85%, rgba(6, 132, 254, 0.07) 126.58%)',
  },
  LOGIN_WRAPPER: {
    width: '470px',
  },
  LOGIN_FORM: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #B8B8B8', 
    borderRadius: '4rem', 
    padding: '3rem',
  },
  LOGIN_LOGO_WRAPPER: {
    display: 'flex',
    justifyContent: 'center',
  },
  LOGIN_TEXT_H1: {
    color: '#2D2D2D', 
    fontWeight: 'bold',
    fontSize: '1.5rem', 
    marginBottom: '0.25rem', 
  },
  LOGIN_TEXT_P_BOLD: {
    color: '#4B4B4B', 
    fontWeight: 'bold',
    fontSize: '0.875rem', 
    marginBottom: '1.75rem', 
  },
  LOGIN_TEXT_LINK: {
    color: '#007BFF', 
    cursor: 'pointer',
  },
  LOGIN_INPUT_WRAPPER: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #CCCCCC', 
    padding: '0.5rem', 
    borderRadius: '1rem', 
  },
  LOGIN_INPUT_LABEL: {
    top: '-1.5rem',
    left: '0',
    color: '#737373', 
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  LOGIN_INPUT: {
    padding: '1rem',
    outline: 'solid 1px',
    border: 'none',
    width: '70%',
    borderRadius: '16px',
    borderColor: "#a2a2a2"
  },
  LOGIN_BUTTON_SUBMIT: {
    display: 'block',
    width: '100%',
    backgroundColor: '#222222', 
    color: '#FFFFFF', 
    marginTop: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '1rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem', 
  },
  LOGIN_FORGOT_PASSWORD: {
    color: '#333333',
    fontSize: '0.875rem',
    marginTop: '0.625rem', 
    cursor: 'pointer',
    '&:hover': {
      color: '#007BFF',
    },
  },
}
export default styles;