import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Apple, Facebook, FbLogoPNG, Google, Logo1024PNG, Selfie } from "../../assets/index";
import styles from "./styles";
import { useUserLoginMutation } from "./apiSlice";
import { Tokens } from "@Constants/common";
import LocalStorage from "@Utils/storage";
import {displayToast} from '../../utils/toast'
import { ROUTES } from "@Constants/routes";
import { setUser } from "@Pages/Profile/profileSlice";
import { useAppDispatch } from "@Store/store";


const Login = () => {

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const navigate = useNavigate();

  const dispatch = useAppDispatch();


  const [userLogin, { data: userLoginData, error: userLoginErr, isLoading: userLoginLoading }] = useUserLoginMutation();

  // const userLogin

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.name == 'email') setEmail(e.target.value);
    if (e?.target?.name == 'password') setPassword(e.target.value);
  };

  const onSubmitClick = (e:any)=>{
    e.preventDefault()
    if (email?.length > 0 && password.length > 0)
      userLogin({ email, password});
  }

  useEffect(() => {
    if (userLoginData) {
      console.log(userLoginData)
      // const notify = () => toast.success("Login Successfull", { theme: 'colored' });
      // notify()
      displayToast('success', 'Login Successful')
      LocalStorage.setItem(Tokens.ACCESS_TOKEN, userLoginData.data.user_access_token);
      LocalStorage.setItem(Tokens.REFRESH_TOKEN, userLoginData.data.refresh_user_access_token);
      dispatch(setUser(userLoginData.user));
      navigate(ROUTES.AUTOMATIONS)
    } else if (userLoginErr) {
      // setShowError(parseErrorMessage(verifyErr));
      console.error(userLoginErr)
      // const notify = () => toast.error(userLoginErr.data.message, { theme: 'colored' });
      // notify()
      displayToast('error', userLoginErr.data.message)
    }
  }, [userLoginData, userLoginErr]);

  return (
    <div className="grid grid-cols-2">
      <div className="h-[100vh] bg-[#EAE8FD] px-[6rem] py-[1rem]">
        <div>
          <p className="font-bold text-2xl">AutoChat</p>
        </div>
        <div className="">
          <img className="w-100" src={Selfie} />
          <div className="mt-[4rem]">
            <p className="font-bold text-[20px]">Welcome back to</p>
            <p className="font-bold text-[64px] text-[#7772FF]">AutoChat!</p>
            <p className="text-[#787781]">Sign in to Autochat.</p>
          </div>
        </div>
      </div>
      <div>
        <div className="absolute right-10 top-10">
          <button className="border-2 border-[#7772FF] font-bold rounded-3xl p-3 text-[14px]">Get Started Free</button>
        </div>
        <div className="flex flex-col items-center justify-center mt-[35%] gap-8">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            onChange={handleChange} style={styles.LOGIN_INPUT} />
          <input type="password"
            name="password"
            autoComplete="false"
            onChange={handleChange}
            id="password"
            placeholder="Password" style={styles.LOGIN_INPUT} />
          {/* <div>
            <p className="font-bold">Forgot Password?</p>
          </div> */}
          <button
            type="button"
            onClick={onSubmitClick} className="bg-[#000000] text-white w-2/3 mx-[8rem] py-3 rounded-xl text-bold text-[14px]">Sign in</button>
          <div className="w-full flex items-center justify-between">
            {/* <hr/>
            <p className="text-[#999999]">Or Login with</p>
            <hr/> */}
          </div>
          {/* <div className="flex justify-between items-center gap-10">
            <div className="flex items-center justify-between gap-2 border-2 rounded-xl py-2 px-4">
              <img src={Apple} />
              <p className="text-xl font-bold">Apple</p>
            </div>
            <div className="flex items-center justify-between gap-2 border-2 rounded-xl py-2 px-4">
              <img src={Google} />
              <p className="text-xl font-bold">Google</p>
            </div>
            <div className="flex items-center justify-between gap-2 border-2 rounded-xl py-2 px-4">
              <img src={Facebook} />
              <p className="text-xl font-bold">Facebook</p>
            </div>
          </div> */}
          <div>
            <p>New to Autochat? <span className="text-[#7772FF]">Sign up</span></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
