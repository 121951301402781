import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import { onLogout } from '@Utils/common';
import { Tokens } from '@Constants/common';
import { API_TAGS, RequestTypes } from '../constants/api';
import LocalStorage from '@Utils/storage';
import { API_DOMAIN } from '@Constants/config';

const mutex = new Mutex();

const fetchQ = fetchBaseQuery({
  baseUrl: API_DOMAIN,
  prepareHeaders: async (headers) => {
    const accessToken = LocalStorage.getItem(Tokens.ACCESS_TOKEN);


    headers.set('Content-Type', 'application/json');
    headers.set('caller', 'web_app');
    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);

    return headers;
  }
});

const getRefreshHeaders = () => {
  const refreshToken = LocalStorage.getItem(Tokens.REFRESH_TOKEN);

  return { 'Content-Type': 'application/json', Authorization: `Bearer ${refreshToken}` };
};

const baseQuery: any = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  try {
    await mutex.waitForUnlock();
    let result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta> = await fetchQ(
      args,
      api,
      extraOptions
    );

    if (result?.error?.status === 401)
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          const refreshResult = await fetch(`${API_DOMAIN}/v0/user/token/refresh`, {
            method: RequestTypes.POST,
            headers: getRefreshHeaders()
          });
          const refreshResultData = await refreshResult.json();

          if (refreshResultData.successful) {
            LocalStorage.setItem(Tokens.ACCESS_TOKEN, refreshResultData.access_token);

            result = await fetchQ(args, api, extraOptions);
          } else {
            // onLogout();
          }
        } catch (error) {
          // onLogout();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await fetchQ(args, api, extraOptions);
      }

    return result;
  } catch (error) {
    return error;
  }
};


export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
  tagTypes: API_TAGS,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});
