import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';
import { clearUser, setUser } from './profileSlice';

const profileApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: (payload) => ({
                url: '/v0/me',
                method: RequestTypes.GET,
                params: payload
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    // if (data?.have_active_order) localStorage.setItem('haveActiveOrder', 'true');
                    // else localStorage.setItem('haveActiveOrder', 'false');
                    dispatch(setUser(data));
                } catch (err) {
                    // localStorage.setItem('haveActiveOrder', 'false');
                    dispatch(clearUser());
                }
            }
        })
    })
});

export const { useLazyGetProfileQuery } = profileApis;
