import { Tokens } from '../constants/common';
import { RootState, useAppSelector } from '../store/store';

import LocalStorage from './storage';

export const onLogout = async () => {
  LocalStorage.removeItem(Tokens.ACCESS_TOKEN);
  LocalStorage.removeItem(Tokens.REFRESH_TOKEN);
};


export const formatTimestampDifference = (timestampString: string) => {
  // Convert timestamp string to a Date object
  const timestamp = new Date(timestampString);

  // Get the current time in milliseconds
  const currentTime = new Date().getTime();

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime - timestamp.getTime();

  // Function to convert milliseconds to hours
  const millisecondsToHours = (ms: number) => {
    return ms / (1000 * 60 * 60);
  };

  // Function to convert milliseconds to days
  const millisecondsToDays = (ms: number) => {
    return ms / (1000 * 60 * 60 * 24);
  };

  // Function to convert milliseconds to months
  const millisecondsToMonths = (ms: number) => {
    return ms / (1000 * 60 * 60 * 24 * 30); // Approximation
  };

  // Format the time difference based on the duration
  const formatTimeDifference = (difference: number) => {
    if (difference < 1) {
      return "Just now";
    } else if (difference < 24) {
      return `${Math.floor(difference)} hours ago`;
    } else if (difference < 30) {
      return `${Math.floor(difference / 24)} days ago`;
    } else {
      const formattedDate = timestamp.toLocaleDateString("en-GB");
      return formattedDate;
    }
  };

  // Calculate the difference in hours, days, and months
  const differenceInHours = millisecondsToHours(timeDifference);
  const differenceInDays = millisecondsToDays(timeDifference);
  const differenceInMonths = millisecondsToMonths(timeDifference);

  // Format the time difference
  const formattedTimeDifference = formatTimeDifference(differenceInHours);

  return formattedTimeDifference;
}