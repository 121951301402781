import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ROUTES } from "@Constants/routes";
import { InboxArrowDownIcon, UserIcon } from '@heroicons/react/24/solid';

const Sidebar = () => {
  return (
    <div className='bg-[#F5F5F5] w-[18%] h-screen border-2'>
      <div className='p-8 flex flex-col gap-4'>
        <NavLink to={ROUTES.USER_DETAILS} className={({ isActive }) =>
          (isActive ? "text-[#887DF2] " : "")}>
          <div className='flex gap-3 item-middle align-center'>
            <UserIcon className={`size-5 `} />
            <span className='text-md'>User Details</span>
          </div>
        </NavLink>
        <NavLink to={ROUTES.CHANNELS} className={({ isActive }) =>
          (isActive ? "text-[#887DF2] " : "")}>
          <div className='flex gap-3 item-middle align-center'>
            <InboxArrowDownIcon className={`size-5 `} />
            <span className='text-md'>Channels</span>
          </div>
        </NavLink>
      </div>
    </div>
  )
}

export default Sidebar