export enum ROUTES {
  ALL = '*',
  HOME = '/',
  SIGNUP_CONNECT_PAGE = "/signup/connect_ig",
  IG_CONNECT_SUCCESS = "/signup/connect_ig/success",
  CONTACTS = "/dashboard/contacts",
  LOGIN="/login",
  SIGNUP_PAGE="/signup",
  CONNECT_FACEBOOK = "/connect/facebook",
  SETTINGS="/setting",
  AUTOMATIONS = "/dashboard/automations",
  EDIT_AUTOMATIONS = "/dashboard/automations/edit/:automation_id",
  USER_DETAILS = "/setting/user",
  CHANNELS = "/setting/channels",
  BLANK = "/"
}
