import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const editAutomationApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAutomation: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/automations/details',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        getAllIGPosts: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/instagram/posts',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        updateAutomation: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/automations/update',
                method: RequestTypes.POST,
                body: payload
            })
        }),
    })
});

export const { useGetAutomationMutation, useGetAllIGPostsMutation, useUpdateAutomationMutation } = editAutomationApis;
