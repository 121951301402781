import React, { useState } from "react";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { CheckBadgeIcon, CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/24/solid";


interface ContactsTableInterface {
  data: any,
  openEditContactModal: (contact_index: number) => void
  openDeleteContactModal: (contactmap_id: number) => void
}


const ContactTable = (props: ContactsTableInterface) => {
  console.log(props?.data);
  
  return (
    <div className="overflow-x-auto">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-left">
          <thead className="text-[1rem] border-b text-[#333333] font-black bg-gray-50">
            <tr>
              {/* <th scope="col" className="p-4"></th> */}
              <th scope="col" className="px-6 py-8">
                Avatar
              </th>
              <th scope="col" className="px-6 py-8">
                Name
              </th>
              <th scope="col" className="px-6 py-8">
                Instagram Username
              </th>
              <th scope="col" className="px-6 py-8">
                You follow the user
              </th>
              <th scope="col" className="px-6 py-8">
                User follow's you
              </th>
              {/* <th scope="col" className="px-6 py-8">
                Contact Custom Attributes
              </th> */}
              <th scope="col" className="px-6 py-8">
                Created Date
              </th>
              {/* <th scope="col" className="px-6 py-8">
                Broadcast
              </th> */}
              <th scope="col" className="px-6 py-8">
                Edit/Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {props?.data?.map((contact:any, index:number) => (
              <tr key={contact.id} className="bg-white font-[400]">
                {/* <td className="w-4 p-4">
                  <div className="flex items-center">
                    <input
                      id={`checkbox-table-search-${contact.id}`}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                    />
                    <label
                      htmlFor={`checkbox-table-search-${contact.id}`}
                      className="sr-only"
                    >
                      checkbox
                    </label>
                  </div>
                </td> */}
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  <img className="h-[50px] rounded-[50%]" src={"https://manychat.com/assets/assets/fb-profile-2B4U3YAA.png"}/>
                </td>
                <td className="px-6 py-4 font-medium text-[1.1rem] text-gray-900 whitespace-nowrap">
                  <p className="">{contact.name}</p>
                </td>
                <td className="px-6 py-4 font-medium text-[1.1rem] text-gray-900 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    {/* <div className="rounded-full bg-[#E9971B] w-6 h-6"></div> */}
                    <p className="">{contact.username}</p>
                  </div>
                </td>
                <td className="px-6 py-4 font-medium text-[1.1rem] text-gray-900 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    {/* <div className="rounded-full bg-[#E9971B] w-6 h-6"></div> */}
                    <p className="">{contact.is_business_follow_user ? <CheckCircleIcon className={`size-6 text-[#887DF2]`} /> : <NoSymbolIcon className={`size-6 text-gray-700 font-`} />}</p>
                  </div>
                </td>
                <td className="px-6 py-4 font-medium text-[1.1rem] text-gray-900 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    {/* <div className="rounded-full bg-[#E9971B] w-6 h-6"></div> */}
                    <p className="">{contact.is_user_follow_business ? <CheckCircleIcon className={`size-6 text-[#887DF2]`} /> : <NoSymbolIcon className={`size-6 text-gray-700 font-`} />}</p>
                  </div>
                </td>
                {/* <td className="px-6 py-4 flex gap-2 text-grey-1">
                  {Object.keys(contact?.custom_attributes).map((key, index) => (
                    <span key={index} className="px-[1rem] py-[.5rem] text-[.8rem] rounded-2xl bg-[#e7eff0]">
                      {key} : {contact?.custom_attributes[key]}
                    </span>
                  ))}
                </td> */}
                <td className="px-6 py-4">{contact.created_at}</td>
                {/* <td className="px-6 py-4">{contact.allowBbroadcast ? "✓" : "❌"}</td> */}
                <td className="flex items-center px-6 py-4 gap-4">
                  {/* <div className="p-2 hover:cursor-pointer hover:bg-[#887DF2] hover:text-[#ffffff] rounded-xl bg-[#f3f4f6]" onClick={(e) => props.openEditContactModal(index)}>
                    <Pencil1Icon />
                  </div> */}
                  <div className="p-2 hover:cursor-pointer hover:bg-[#887DF2] hover:text-[#ffffff] rounded-xl bg-[#f3f4f6]" onClick={(e) => props.openDeleteContactModal(contact.ig_channel_map_id)}>
                    <TrashIcon />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactTable;
