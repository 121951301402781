import React, { InputHTMLAttributes, useState } from 'react'
import ReactModal from 'react-modal'
import styles from './styles'
import { RootState, useAppSelector } from '@Store/store';
import { Avatar } from '@assets/index';
import { Label } from '@Components/shadcn/ui/label';
import {useUserUpdateMutation} from '../../apiSlice'
import { displayToast } from '@Utils/toast';

const UserDetailsComponent = () => {

    const { user } = useAppSelector((state: RootState) => state.profile);

    const [userUpdate, {data: userUpdateData, error: useLoginErr}] = useUserUpdateMutation()


    const [userData, setUserData] = useState({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_number: user?.phone_number 
    })
    const [changePasswordModal, setChangePasswordModal] = useState(false)

    const openChangePasswordModal = () => {
        setChangePasswordModal(!changePasswordModal)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setUserData(prev => ({
            ...prev,
            [name] : value
        }))
    }

    const handleSave = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        e.preventDefault()
        
        userUpdate(userData)
        if(userUpdateData?.status){
            displayToast('success', userUpdateData?.message)
        }else{
            displayToast('error', userUpdateData?.message)
        }
    }

    return (
        <div className='w-full'>
            <div className='flex p-8 justify-between items-center border-b-2'>
                <h1 className='text-2xl font-bold'>User Profile</h1>
                <button className='border-2 border-[#16a34a]  rounded-[0.5rem] px-4 py-2 bg-[#16a34a] text-white font-semibold' onClick={(e) => handleSave(e)}>Save</button>
            </div>
            <div className='p-8 grid grid-cols-4 gap-4 mt-[6rem] w-full'>
                <div>
                    <img src={user?.profile_picture || Avatar} className='border-2 rounded-full w-[8rem] h-[8rem] mx-auto' />
                </div>
                <div className='flex col-span-2 flex-col gap-4'>
                    <div className='flex flex-row gap-5 w-full'>
                        <div className=' w-full'>
                            <Label htmlFor="firstName">First Name</Label>
                            <input type='text' id="firstName" name='first_name' className='mt-2 border-2 px-4 py-2 w-full' placeholder='first name' value={userData.first_name} onChange={(e) => handleChange(e)} />
                        </div>
                        <div className=' w-full'>
                            <Label htmlFor="firstName">Last Name</Label>
                            <input type='text' name='last_name' className='mt-2 border-2 px-4 py-2 w-full' placeholder='last name' value={userData.last_name} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div>
                            <Label htmlFor="email">Email</Label>
                            <input type='text' className='mt-2 border-2 px-4 py-2 w-full' placeholder='Email' name='email' id="email" value={userData.email} onChange={(e) => handleChange(e)} />
                        </div>
                        <div>
                            <Label htmlFor="phoneNumber">Phone Number</Label>
                            <input id="phone" type='text' className='mt-2 border-2 px-4 py-2 w-full' placeholder='phone' name='phone_number' value={userData.phone_number} onChange={(e) => handleChange(e)} />
                        </div>
                    </div>





                    {/* <button className='border-2 border-[#16a34a] rounded-[0.5rem] px-4 py-2 bg-[#16a34a] text-white font-semibold' onClick={() => openChangePasswordModal()}>Change Password</button>
                <ChangePasswordModal isModalOpen={changePasswordModal} /> */}
                </div>
            </div>
        </div>
    )
}

interface ChangePasswordModal {
    isModalOpen: boolean
}

const ChangePasswordModal = (props: ChangePasswordModal) => {
    return (
        <ReactModal
            isOpen={props.isModalOpen}
            style={
                { overlay: {}, content: styles.deleteContactModalContent }
            }
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            role={"dialog"}
            preventScroll={false}
        >
            <div>
                <p>To change password,</p>
                <p>contact admin</p>
            </div>
        </ReactModal>
    )
}

export default UserDetailsComponent