export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "";
export const APPWRITE_PROJECT_ID =
  process.env.REACT_APP_APPWRITE_PROJECT_ID || "";
export const APPWRITE_API_ENDPOINT =
  process.env.REACT_APP_APPWRITE_API_ENDPOINT || "";
export const APPWRITE_API_KEY_SECRET =
  process.env.REACT_APP_APPWRITE_API_KEY_SECRET || "";
export const CRYPTOJS_KEY = process.env.REACT_APP_CRYPTOJS_KEY || ""
export const CRYPTOJS_IV = process.env.REACT_APP_CRYPTOJS_IV || ""

// export const WEB_URL = process.env.REACT_APP_WEB_URL;
// export const GTM_ID = process.env.REACT_APP_GTM_ID || '';
