import { useLottie } from 'lottie-react';

import { LoaderProps } from './type';
import LoaderLottie from '@assets/lottie/loader.json';
import styles from './styles';

const Loader = ({ isLoading, isWhiteBackground = false }: LoaderProps) => {
  const options = {
    animationData: LoaderLottie,
    loop: true
  };

  const { View } = useLottie(options);

  if (!isLoading) return null;

  return (
    <div style={styles.loaderWrapper}>
      <div style={{ width: 350 }}>{View}</div>
    </div>
  );
};

export default Loader;
