import Sidebar from '@Components/Sidebar'
import Navbar from '@Components/navbar'
import React from 'react'

const Setting = () => {
  return (
    <div>
        <Navbar/>
        <div>
          <Sidebar/>
        </div>
    </div>
  )
}

export default Setting