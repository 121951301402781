import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const channelApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getChannels: builder.query({
            query: (payload) => ({
                url: '/v0/user/channel',
                method: RequestTypes.GET,
                params: payload
            })
        }),
        disconnectIG: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/instagram/disconnect',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useLazyGetChannelsQuery, useDisconnectIGMutation } = channelApis;
