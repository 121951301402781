import React from "react";
import styles from "./styles";

export interface IG_CONNECT_CARD_PROPS {
  name: string,
  imageURL: string,
  buttonClickFn: ()=>void
}

const IgConnectCard = ({ name, imageURL, buttonClickFn }: IG_CONNECT_CARD_PROPS) => {
  return (
    <div className="sm:pt-4" style={styles.CONTAINER}>
      <div className="rtl:space-x-reverse" style={styles.WRAPPER}>
        <div className="flex-shrink-0" style={styles.PROFILE_WRAPPER}>
          <img src={imageURL} alt="Neil" style={styles.PROFILE_IMAGE} />
        </div>
        <div style={styles.NAME_WRAPPER}>
          <p style={styles.TEXT_P_BOLD}>{name}</p>
        </div>
        <div style={styles.BUTTON_WRAPPER}>
          <button className={`btn-blue`} onClick={buttonClickFn}>
            Connect
          </button>
        </div>
      </div>
    </div>
  );
};

export default IgConnectCard;
