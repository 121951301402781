import {baseApi} from '@Services/api'
import { RequestTypes } from '@Constants/api'

const userDetailsApis = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        userUpdate: builder.mutation({
          query: (payload) => ({
            url: '/v0/users/profile/update',
            method: RequestTypes.PUT,
            body: payload
          })
        })
    })
})

export const { useUserUpdateMutation } = userDetailsApis