import { FBBlue, Facebook, Google } from '@assets/index'
import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import OTPInput from 'react-otp-input'

import styles from './styles'
import { useUserSignupMutation, useVerifyOtpMutation } from './signupSlice'
import LocalStorage from '@Utils/storage'
import { Tokens } from '@Constants/common'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@Constants/routes'
import { displayToast } from '@Utils/toast'
import { setUser } from '@Pages/Profile/profileSlice'
import { useAppDispatch } from '@Store/store'

const SignupForm = () => {
    const navigate = useNavigate();
    
    // const notify = () => toast.error("Wow so easy !", { theme: 'colored'});
    
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string|any>('')
    const [otp, setOtp] = useState<string>('')
    const [showOtp, setShowOtp] = useState<boolean>(false)


    const [userSignup, { data: userSignupData, error: userSignupErr, isLoading: userSignupLoading }] = useUserSignupMutation();
    const [verifyOtp, {data: verifyOtpData}] = useVerifyOtpMutation()

    const dispatch = useAppDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        if (e?.target?.name == 'email') setEmail(e.target.value);
        if (e?.target?.name == 'password') setPassword(e.target.value);
        if (e?.target?.name == 'firstname') setFirstName(e.target.value);
        if (e?.target?.name == 'lastname') setLastName(e.target.value);
        if (e?.target?.name == 'phonenumber') setPhoneNumber(e.target.value);
    };
    const handlePhoneChange = (val: any) => {
        setPhoneNumber(val);
    };

    const onSubmitClick = (e: any) => {
        e.preventDefault()
        // notify()
        if (email?.length > 0 && password.length > 0)
            userSignup({ email, password, first_name: firstName, last_name: lastName, phone_number: phoneNumber });
    }

    const handleOtp = (e:any) => {
        e.preventDefault()
        verifyOtp({otp})
    }

    useEffect(() => {
        if (userSignupData?.status) {
            LocalStorage.setItem(Tokens.ACCESS_TOKEN, userSignupData.data.user_access_token);
            LocalStorage.setItem(Tokens.REFRESH_TOKEN, userSignupData.data.refresh_user_access_token);
          
            displayToast("success", "User Signup Successful")
            if(userSignupData?.user?.verified == 0){
                setShowOtp(true)
            }
            dispatch(setUser(userSignupData.user));
            if (userSignupData.status) navigate(ROUTES.CONNECT_FACEBOOK)
          
        } else if (userSignupErr) {
            // setShowError(parseErrorMessage(verifyErr));
            console.error(userSignupErr)
        }
    }, [userSignupData, userSignupErr]);

    useEffect(() => {
        if (verifyOtpData?.status) {
            navigate(ROUTES.CONNECT_FACEBOOK);
        }
    }, [verifyOtpData]);

  return (
    <div className='w-[100%] px-[2rem]'>
        <div className='relative right-0 mt-5'>
            <p className='font-bold' style={styles.TITLE}>Create your free account</p>
            {/* <p className='text-[12px] text-[#666666]'>Lorem ipsum dolor sit amet consectetur.</p> */}
        </div>
        {showOtp ? (
            <>
                <form className='mt-8 mb-8'>
                    <div className='flex justify-center items-center text-2xl'>
                        <OTPInput 
                        value={otp} 
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={styles.OTP_INPUT}
                        />
                    </div>
                </form>
                {/* <div className='mx-[1rem] text-center'>
                    <p className='text-[12px] text-[#666666]'>By signing up you agree to the <span style={styles.TEXT_LINK}>Terms</span> and <span style={styles.TEXT_LINK}>Privacy Policy</span></p>
                </div> */}
                <div className='flex justify-center items-center my-4'>
                    <button className='p-2' style={styles.SUBMIT_BUTTON} onClick={handleOtp}>Verify</button>
                </div>
            </>
        ) : (
            <>
                <form className='mt-8 mb-8'>
                    <div>
                        <div className='flex items-center gap-4'>
                            <input type='text' name="firstname" placeholder='First Name' className='w-[50%]' value={firstName} style={styles.INPUT_TEXT} onChange={handleChange}/>
                            <input type='text' name="lastname" placeholder='Last Name' className='w-[50%]' value={lastName} style={styles.INPUT_TEXT} onChange={handleChange} />
                        </div>
                        <div className='mt-4'>
                                <input type='email' name="email" placeholder='Email Address' className='w-[100%]' value={email} style={styles.INPUT_TEXT} onChange={handleChange} />
                        </div>
                        <div className='mt-4'>
                                <input type='password' name="password" placeholder='Password' className='w-[100%]' value={password} style={styles.INPUT_TEXT} autoComplete="new-password" onChange={handleChange} />
                        </div>
                        <div className='mt-4'>
                                {/*  */}
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    defaultCountry="IN"
                                    countryCallingCodeEditable={false}
                                    international
                                    initialValueFormat="national"
                                    value={phoneNumber}
                                //   inputComponent={
                                //       () => <input type='tel' name="phonenumber" placeholder='Your Phone Number' className='w-[100%]' style={styles.PHONE_INPUT}/>
                                // }
                                    onChange={setPhoneNumber}
                                    style={styles.INPUT_TEXT}
                                    />
                        </div>
                    </div>
                </form>
                <div className='mx-[1rem] text-center'>
                    <p className='text-[12px] text-[#666666]'>By signing up you agree to the <span style={styles.TEXT_LINK}>Terms</span> and <span style={styles.TEXT_LINK}>Privacy Policy</span></p>
                </div>
                <div className='flex justify-center items-center my-4'>
                    <button className='p-2' style={styles.SUBMIT_BUTTON} onClick={onSubmitClick}>Signup</button>
                </div>
            </>
        )}
    </div>
  )
}

export default SignupForm