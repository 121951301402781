const styles = {
    BACKGROUND: {
        backgroundColor: '#f5f6fa',
        width: "100%",
        minHeight: "100vh"
    },
    ADD_BUTTON:{
        color: 'white',
        backgroundColor: '#208A4A',
        padding: "0.85rem",
        borderRadius: "10px",
        fontSize: "14px"
    },
    editContactModalContent: {
        height: '70%',
        width: '50%',
        margin: 'auto'

    },
    deleteContactModalContent: {
        height: 'fit-content',
        width: 'fit-content',
        margin: 'auto',
        fontFamily: 'DM Sans',
        borderRadius: '20px'
    },
    modalHeader: {
        fontSize: '1.3rem',
        color: "#000000d9",
        borderBottom: '2px solid #22242626'
    },
    modalContent: {
        fontSize: '1rem'
    },
    redButton: {
        borderColor: 'rgb(220 38 38)',
        backgroundColor: 'rgb(220 38 38)',
        color: '#fff'
    },
    greenButton: {
        borderColor: '#23a455',
        backgroundColor: '#23a45500',
        color: '#23a455'
    }
}

export default styles