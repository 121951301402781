import Sidebar from '@Components/Sidebar'
import Navbar from '@Components/navbar'
import React, { useEffect, useState } from 'react'
import { useDisconnectIGMutation, useLazyGetChannelsQuery } from './apiSlice'
import { Avatar } from '@assets/index'

import ReactModal from 'react-modal';
import styles from './styles'

const Channels = () => {

  const [disconnectModal, setDisconnectModal] = useState(false)
  const [disconnectChannelData, setDisconnectChannelData] = useState({
    igpid: '-1',
    igid: '-1'
  })

  const [getChannels, { data: channelsData, isLoading: isChannelsLoading }] = useLazyGetChannelsQuery()

  const closeDisconnectModal = () => {
    setDisconnectModal(!disconnectModal)
  }

  useEffect(() => {
    getChannels({})
  }, [])

  useEffect(() => {
    console.log(channelsData);
  }, [channelsData])

  const onDisconnectBtnClick = (igid:string, igpid:string) => {
    setDisconnectModal(true)
    setDisconnectChannelData({
      igid, igpid
    })
  }

  const refreshChannelData = () => {
    getChannels({})
  }


  return (
    <div>
      <Navbar />
      <div className='flex'>
        <Sidebar />
        <div className='w-full'>
          <div className='p-8 border-b-2'>
            <h1 className='text-2xl font-bold'>Channels</h1>
          </div>
          <div className='grid grid-cols-5 gap-4 p-8'>
            {channelsData && channelsData?.channel?.instagram?.map((el: any, idx: number) => {
              return (
                <div className='flex flex-col border-2 rounded-lg p-3'>
                  <div className='p-2'>
                    <img className='w-[100px] mx-auto rounded-full' src={el.profile_picture_url} />
                  </div>
                  <div className='p-3 w-full text-center'>
                    <p className='text-xl font-bold'>{el.username}</p>
                    <p className='text'>{el.name}</p>
                    <button className="px-6 py-1 w-full mt-2 border-solid rounded bg-[#ef4444] hover:bg-[#dc2626] text-white" onClick={() => onDisconnectBtnClick(el.instagram_id, el.facebook_page_id)}>Disconnect</button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <DeleteAutomationModal isModalOpen={disconnectModal} disconnectModalOnClose={closeDisconnectModal} disconnectChannelData={disconnectChannelData} refreshChannel={refreshChannelData}/>
    </div>
  )
}



interface DeleteAutomationModalInterface {
  isModalOpen: boolean;
  disconnectModalOnClose: () => void;
  disconnectChannelData: any
  refreshChannel: ()=>void;
}


const DeleteAutomationModal = (props: DeleteAutomationModalInterface) => {

  const [disconnectIG, { data: disconnectIGData, error: disconnectIGErr, isLoading: isDisconnectIGLoading }] = useDisconnectIGMutation()


  useEffect(() => {
    if (disconnectIGData) {
      console.log(disconnectIGData);
      props.disconnectModalOnClose()
      props.refreshChannel()
    }
    if (disconnectIGErr) console.log(disconnectIGErr);
  }, [disconnectIGData, disconnectIGErr])

  const disconnectBtnClick = (e:any) => {
    console.log(props.disconnectChannelData)
    disconnectIG(props.disconnectChannelData)
  }


  return (
    <ReactModal
      isOpen={props.isModalOpen}
      onRequestClose={props.disconnectModalOnClose}
      style={
        { overlay: {}, content: styles.deleteContactModalContent }
      }
      portalClassName={"ReactModalPortal"}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      role={"dialog"}
      preventScroll={false}
    >
      <>
        <div className="pb-[.5rem] border-b-2 border-slate-300" style={styles.modalHeader}>
          <h3>Confirm</h3>
        </div>
        <div className="py-[1rem]" style={styles.modalContent}>
          <p>Are you sure to disconnect this channel?</p>
          <div className="flex justify-end mt-[2rem]">
            <button className="px-6 py-1 border-2 border-solid rounded" style={styles.redButton} onClick={disconnectBtnClick} disabled={isDisconnectIGLoading}>Disconnect</button>
            <button
              className="px-6 py-1 border-2 border-solid rounded border-green-500 mx-2 w-[150px]"
              style={styles.greenButton}
              onClick={props.disconnectModalOnClose}>
              Cancel
            </button>
          </div>
        </div>
      </>
    </ReactModal>
  )
}

export default Channels