
const MobileMessage = () => {
    console.log("hit");
    
  return (
    <div className="fixed inset-0 flex items-center justify-center backdrop-blur-xl bg-black/50">
      <div className="bg-white p-4 rounded-lg">
        <p className="text-3xl">Switch to desktop to use the app</p>
      </div>
    </div>
  );
};

export default MobileMessage;