import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AddIconSVG, Logo1024PNG, RefreshIconSVG, Selfie2 } from "../../assets/index";
import IgConnectCard from "../../components/IgConnectCard/IgConnectCard";
import styles from "./styles";
import { useRefreshIGMutation, useLazyUserFBAccountQuery, useConnectIGMutation } from "./apiSlice";
import { ROUTES } from "@Constants/routes";
import LocalStorage from "@Utils/storage";
import { displayToast } from "@Utils/toast";

const SignUpConnect = () => {
  const [refreshIG, { data: refreshIGData, error: refreshIGErr, isLoading: refreshIGLoading }] = useRefreshIGMutation();
  const [getUserFBAccount, { data: userFBAccountData, isLoading: userFBAccountLoading }] = useLazyUserFBAccountQuery();
  const [connectIG, { data: connectIGData, error: connectIGErr, isLoading: connectIGLoading }] = useConnectIGMutation();


  const [fbAccountData, setFbAccountData] = useState([])
  const [fbAccountIds, setFbAccountIds] = useState<any>([])
  const [IGAccounts, setIGAccounts] = useState([])
  const [fbIGMap, setfbIGMap] = useState<any>({})
  const [ConnectedIGAccount, setConnectedIGAccount] = useState<any>({})


  const navigate = useNavigate();

  useEffect(() => {
    if (refreshIGData) console.log(refreshIGData);
  }, [refreshIGData, refreshIGErr])


  useEffect(() => {
    getUserFBAccount({})
  }, [])
  

  useEffect(() => {
    if (userFBAccountData) {
      console.log(userFBAccountData.data);
      setFbAccountData(userFBAccountData.data)
      const temp_user_ids = []
      for (let i = 0; i < userFBAccountData.data.length; i++) {
        const fb_account = userFBAccountData.data[i];
        temp_user_ids.push(fb_account?.user_id)
        
      }
      setFbAccountIds(temp_user_ids)
    }
  }, [userFBAccountData])

  useEffect(() => {
    console.log(fbAccountData);
    
    let temp_ig_accounts:any = []
    let fb_ig_map: any = {}
    for (let i = 0; i < fbAccountData.length; i++) {
      const fb:any = fbAccountData[i];


      for (let j = 0; j < fb?.instagram_accounts.length; j++) {
        const ig_account = fb.instagram_accounts[j];
        temp_ig_accounts.push(ig_account)
        fb_ig_map[ig_account.page_id] = fb.user_id
      }
    }
    setIGAccounts(temp_ig_accounts)
    console.log(fb_ig_map)
    setfbIGMap(fb_ig_map)
  }, [fbAccountData])

  const connectIGButton = (igAccount: any) => {
    setConnectedIGAccount(igAccount)
    connectIG({ fbid: fbIGMap[igAccount.page_id], igpid: igAccount.page_id })
    
  }

  useEffect(() => {
    if (connectIGData) {
      displayToast('success', 'Instagram Account connected')
      LocalStorage.setItem('igu', ConnectedIGAccount?.username)
      LocalStorage.setItem('ppu', ConnectedIGAccount?.profile_picture_url)
      if (connectIGData.status) navigate(`${ROUTES.IG_CONNECT_SUCCESS}`, )
    };
    if (connectIGErr){
      displayToast('error', connectIGErr.data.message)
      console.log(connectIGErr);
    } 
  }, [connectIGData, connectIGErr])
  
  
  
  
  return (

    <div className="grid grid-cols-2">
      <div className="h-[100vh] bg-[#EAE8FD] px-[1rem] py-[1rem]">
        <div>
          <p className="font-bold text-2xl">AutoChat</p>
        </div>
        <div className="mt-[1rem]">
          <img src={Selfie2} className="w-[70%] item-center justify-center" />
          <div className="mt-[4rem]">
            <p className="font-bold text-[48px]">Connect Instagram</p>
            <p className="text-[#787781]">Follow the instruction to connect to instagram, account</p>
          </div>
        </div>
      </div>
      <div className="flex item-center justify-center  align-middle my-auto">
        {/* <div className="absolute right-10 top-10">
            <button className="border-2 border-[#7772FF] font-bold rounded-3xl p-3 text-[14px]">Get Started Free</button>
        </div> */}
        <div className="w-[80%] flex item-center justify-center" style={styles.SIGNUP_CONTAINER}>
          <div>
            <div className="container-head">
              <div style={styles.LOGO_WRAPPER}>
                <h1 className="text-[1.5rem] font-bold">Select Instagram Account</h1>
              </div>
              <p className="text-center" style={styles.TEXT_P}>
                We found <span className="font-bold">{IGAccounts.length} Instagram account</span> linked with Facebook Pages managed by
              </p>
            </div>
            <div className="container-body flex flex-col gap-3 my-[2rem]" style={styles.CARD_WRAPPER}>
              {
                IGAccounts.map((e: any, idx) => {
                  console.log(e);
                  return <IgConnectCard name={e.name} key={e.page_id} buttonClickFn={() => { connectIGButton(e) }} imageURL={e.profile_picture_url} />
                })
              }
            </div>
            <div style={styles.ACTIONS_CONTAINER}>
              <p style={styles.ACTIONS_WRAPPER}>
                <img src={RefreshIconSVG} style={styles.ICON_STYLE} />
                <span className="text-blue-1 leading-5" onClick={() => refreshIG({ fbids: fbAccountIds })}>Refresh Account List</span>
              </p>
              <p style={styles.ACTIONS_WRAPPER}>
                <img src={AddIconSVG} style={styles.ICON_STYLE} />
                <span className="text-blue-1 leading-5">How to connect IG Account?</span>
              </p>
            </div>
          </div>
        </div>
      </div>


    </div>
    
  );
};

export default SignUpConnect;
