const styles = {
    TITLE: {
        fontSize: '20px'
    },
    INPUT_TEXT: {
        border: '1px solid #a2a2a2',
        borderRadius: '10px',
        padding: '.5rem'
    },
    PHONE_INPUT: {
        outline: 'none',
        ':focusVisible :focusWithin :focus': {
            outline: '#000 auto 0px'
        }
    },
    TEXT_LINK: {
        color: '#007BFF', // Assuming 'text-blue-2' is equivalent to '#007BFF'
        cursor: 'pointer',
    },
    SUBMIT_BUTTON: {
        backgroundColor: '#000',
        width: '80%',
        color: '#fff',
        borderRadius: '10px',
        fontWeight: 'bold'

    },
    OTP_INPUT: {
        width: '2rem',
        height: '2rem',
        margin: '0 0.5rem',
        fontSize: '1.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc'
    }
}
export default styles