import { baseApi } from '@Services/api';
import { RequestTypes } from '@Constants/api';

const connectInstagramAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        userFBAccount: builder.query({
            query: (payload:any) => ({
                url: '/v0/user/accountsFb',
                method: RequestTypes.GET,
                params: payload
            })
        }),
        refreshIG: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/instagram/refresh',
                method: RequestTypes.POST,
                body: payload
            })
        }),
        connectIG: builder.mutation({
            query: (payload) => ({
                url: '/v0/user/instagram/add',
                method: RequestTypes.POST,
                body: payload
            })
        })
    })
});

export const { useLazyUserFBAccountQuery, useRefreshIGMutation, useConnectIGMutation } = connectInstagramAPI;
