const styles = {
    BACKGROUND: {
        backgroundColor: '#f5f6fa',
        width: "100%",
        minHeight: "100vh"
    },
    ADD_BUTTON: {
        color: 'white',
        backgroundColor: '#208A4A',
        padding: "0.85rem",
        borderRadius: "10px",
        fontSize: "14px"
    },
    deleteContactModalContent: {
        height: '220px',
        width: '400px',
        margin: 'auto',
        fontFamily: 'DM Sans',
        borderRadius: '20px'
    },
    modalHeader: {
        fontSize: '1.3rem',
        color: "#000000d9",
        borderBottom: '2px solid #22242626'
    },
    modalContent: {
        fontSize: '1rem'
    },
    redButton: {
        borderColor: 'rgb(220 38 38)',
        // backgroundColor: 'rgb(220 38 38)',
        color: 'rgb(220 38 38)'
    },
    greenButton: {
        backgroundColor: '#23a455',
        borderColor: '#23a455',
        color: '#ffffff'
    }
}

export default styles